/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import Link from "next/link";
import Image from "next/image";
import logoCH1 from "../../assets/images/logoCH1.png";
import logoCHW from "../../assets/images/logoCHW.png";
import { Fragment, useState, useEffect } from "react";
import { sanitizeProjectURL } from "../../utility/helper";
import { useRouter } from "next/router";

export default function Header(props) {
  const [topBar, getTop] = useState([]);
  const [reginData, setreginData] = useState(props.nav.reginData);
  const [destinationData, setdestination] = useState(props.nav.destination);
  const [contactus, setcontactus] = useState(props.nav.contactus);
  const [socialMedia, setsocialMedia] = useState(props.nav.socialMedia);
  const [pagecontainerData, sePagecontainerData] = useState(
    props.nav.pagecontainerData
  );
  const [pageData, setPageData] = useState(props.nav.pageData);
  const [toggleBtn, setToggle] = useState(false);
  const [CookiesUtm, setCookiesUtm] = useState({});
  const [CookiesReferal, setCookiesReferal] = useState("");
  const router = useRouter();
  const [utmParams, setUtmParams] = useState({});

  // Use effect to handle utm parameters
  useEffect(() => {
    const {
      utm_source,
      utm_Source,
      Utm_Source,
      utm_medium,
      utm_Medium,
      Utm_Medium,
      utm_campaign,
      utm_Campaign,
      Utm_Campaign,
      utm_term,
      utm_Term,
      Utm_Term,
      utm_content,
      utm_Content,
      Utm_Content,
    } = router.query;
    setUtmParams({
      utm_source: utm_source || utm_Source || Utm_Source,
      utm_medium: utm_medium || utm_Medium || Utm_Medium,
      utm_campaign: utm_campaign || utm_Campaign || Utm_Campaign,
      utm_term: utm_term || utm_Term || Utm_Term,
      utm_content: utm_content || utm_Content || Utm_Content,
    });
  }, [router.query]);

  useEffect(() => {
    setToggle(false);
  }, [router.asPath]);

  return (
    <Fragment>
      <header id="masthead" className="site-header header-primary sticky-top">
        <div className="top-header">
          <div className="container theme-container">
            <div className="row d-lg-none d-flex border-bottom">
              <div className="col-12">
                <div className="header-social social-links">
                  <ul className="row">
                    <li>
                      <a
                        className="text-dark"
                        href={`tel:${contactus.mainPhone}`}
                      >
                        <i
                          className="fa fa-phone-square mr-1 position-relative top-2 text-main"
                          style={{ fontSize: "18px" }}
                        ></i>{" "}
                        <span className="fw-700" style={{ fontSize: "14px" }}>
                          {contactus.mainPhone}
                        </span>
                      </a>
                    </li>
                    {socialMedia?.map((social, index) => {
                      return (
                        <Fragment key={index}>
                          {social.isActive && (
                            <li
                              key={social.id}
                              className={`order-${social?.order}`}
                            >
                              <a
                                href={social?.socialMediaURL}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Image
                                  loading="lazy"
                                  width="25px"
                                  height="25"
                                  src={social.iconURL ? social.iconURL : ""}
                                />
                              </a>
                            </li>
                          )}
                        </Fragment>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="row d-flex justify-content-between"
              style={{ height: "73px" }}
            >
              <div className="col-lg-4 col-5 d-flex  justify-content-start">
                <div className="site-identity">
                  <div className="site-title" style={{ paddingTop: "25px" }}>
                    {utmParams.utm_source ||
                    utmParams.utm_medium ||
                    utmParams.utm_campaign ||
                    utmParams.utm_term ||
                    utmParams.utm_content ? (
                      <>
                        <Link
                          href={{
                            pathname: "/",
                            query: {
                              utm_source: utmParams.utm_source,
                              utm_medium: utmParams.utm_medium,
                              utm_campaign: utmParams.utm_campaign,
                              utm_term: utmParams.utm_term,
                              utm_content: utmParams.utm_content,
                            },
                          }}
                          passHref
                        >
                          <span className="white-logo cursor-pointer">
                            <Image alt="Central Holidays" src={logoCHW} />
                          </span>
                        </Link>
                        <Link
                          href={{
                            pathname: "/",
                            query: {
                              utm_source: utmParams.utm_source,
                              utm_medium: utmParams.utm_medium,
                              utm_campaign: utmParams.utm_campaign,
                              utm_term: utmParams.utm_term,
                              utm_content: utmParams.utm_content,
                            },
                          }}
                          passHref
                        >
                          <span className="black-logo cursor-pointer">
                            <img
                              width="280"
                              height="auto"
                              src={
                                props?.nav?.company?.logo
                                  ? props?.nav?.company?.logo
                                  : logoCH1
                              }
                              alt="Central Holidays"
                            ></img>
                          </span>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link href="/" passHref>
                          <span className="white-logo cursor-pointer">
                            <Image alt="Central Holidays" src={logoCHW} />
                          </span>
                        </Link>
                        <Link href="/" passHref>
                          <span className="black-logo cursor-pointer">
                            <img
                              width="280"
                              height="auto"
                              src={
                                props?.nav?.company?.logo
                                  ? props?.nav?.company?.logo
                                  : logoCH1
                              }
                              alt="Central Holidays"
                            ></img>
                          </span>
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 d-none d-lg-flex justify-content-around">
                <div className="header-contact-info d-flex w-100 justify-content-center">
                  <ul className="d-flex align-items-center w-100 justify-content-around">
                    <li>
                      <Link href={`tel:${contactus.mainPhone}`}>
                        <>
                          <i className="fa fa-phone"></i>{" "}
                          <span style={{ fontSize: "15px" }}>
                            {contactus.mainPhone}
                          </span>
                        </>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className="col-lg-4 col-5 d-lg-flex d-none justify-content-end">
                <div className="header-social social-links">
                  <ul className="row">
                    <li>
                      <a
                        className="text-dark"
                        href={`tel:${contactus.mainPhone}`}
                      >
                        <i
                          className="fa fa-phone-square mr-1 position-relative top-2 text-main"
                          style={{ fontSize: "20px" }}
                        ></i>{" "}
                        <span className="fw-700" style={{ fontSize: "15px" }}>
                          {contactus.mainPhone}
                        </span>
                      </a>
                    </li>
                    {socialMedia?.map((social, index) => {
                      return (
                        <Fragment key={index}>
                          {social.isActive == true && (
                            <li
                              key={social.id}
                              className={`order-${social.order}`}
                            >
                              <a
                                href={social.socialMediaURL}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Image
                                  loading="lazy"
                                  width="25px"
                                  height="25"
                                  src={social.iconURL ? social.iconURL : ""}
                                />
                              </a>
                            </li>
                          )}
                        </Fragment>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="mobile-menu-container mb-1 d-flex d-lg-none col-2 align-items-center">
                <div onClick={() => setToggle(!toggleBtn)} className="btnMenu">
                  <i className="text-main fa-solid fa-bars mx-2 "></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-header d-none d-lg-block">
          <div className="container theme-container">
            <div className="row">
              <div className="col-12">
                <div className="main-navigation">
                  <nav id="navigation" className="navigation">
                    <ul className="d-flex justify-content-around">
                      <li className="menu-item-has- ">
                        {utmParams.utm_source ||
                        utmParams.utm_medium ||
                        utmParams.utm_campaign ||
                        utmParams.utm_term ||
                        utmParams.utm_content ? (
                          <Link
                            href={{
                              pathname: "/",
                              query: {
                                utm_source: utmParams.utm_source,
                                utm_medium: utmParams.utm_medium,
                                utm_campaign: utmParams.utm_campaign,
                                utm_term: utmParams.utm_term,
                                utm_content: utmParams.utm_content,
                              },
                            }}
                          >
                            Home
                          </Link>
                        ) : (
                          <Link href="/"> Home</Link>
                        )}
                      </li>
                      <li className="menu-item-has-children">
                        <a>Destinations</a>
                        <ul>
                          {destinationData?.map((dest, index) => (
                            <Fragment key={index}>
                              {reginData?.map((region, index) => (
                                <Fragment key={index}>
                                  {region.countriesId.map((id, i) => {
                                    const countryName =
                                      region.countriesNames[i];
                                    return (
                                      dest.destinationId === id &&
                                      dest.isNavShown && (
                                        <li key={dest.id} className="nav-item">
                                          {utmParams.utm_source ||
                                          utmParams.utm_medium ||
                                          utmParams.utm_campaign ||
                                          utmParams.utm_term ||
                                          utmParams.utm_content ? (
                                            <Link
                                              href={{
                                                pathname: `/destination/${dest?.pageURL}`,
                                                query: {
                                                  utm_source:
                                                    utmParams.utm_source,
                                                  utm_medium:
                                                    utmParams.utm_medium,
                                                  utm_campaign:
                                                    utmParams.utm_campaign,
                                                  utm_term: utmParams.utm_term,
                                                  utm_content:
                                                    utmParams.utm_content,
                                                },
                                              }}
                                            >
                                              <a className="nav-link">
                                                {countryName}
                                              </a>
                                            </Link>
                                          ) : (
                                            <Link
                                              href={{
                                                pathname: `/destination/${dest?.pageURL}`,
                                              }}
                                            >
                                              <a className="nav-link">
                                                {countryName}
                                              </a>
                                            </Link>
                                          )}
                                        </li>
                                      )
                                    );
                                  })}
                                </Fragment>
                              ))}
                            </Fragment>
                          ))}
                          {reginData?.map((region) => {
                            return (
                              <li
                                key={region.id}
                                className="nav-item menu-item-has-children"
                              >
                                {utmParams.utm_source ||
                                utmParams.utm_medium ||
                                utmParams.utm_campaign ||
                                utmParams.utm_term ||
                                utmParams.utm_content ? (
                                  <Link
                                    href={{
                                      pathname: region?.regionURL
                                        ? `/region/${region?.regionURL}`
                                        : "/",
                                      query: {
                                        utm_source: utmParams.utm_source,
                                        utm_medium: utmParams.utm_medium,
                                        utm_campaign: utmParams.utm_campaign,
                                        utm_term: utmParams.utm_term,
                                        utm_content: utmParams.utm_content,
                                      },
                                    }}
                                  >
                                    <a className="nav-link">{region.name}</a>
                                  </Link>
                                ) : (
                                  <Link
                                    href={{
                                      pathname: region?.regionURL
                                        ? `/region/${region?.regionURL}`
                                        : "/",
                                    }}
                                  >
                                    <a className="nav-link">{region.name}</a>
                                  </Link>
                                )}
                                <ul className="dropdown-menu">
                                  {region.countriesId.map((id, i) => {
                                    const countryName =
                                      region.countriesNames[i];
                                    return (
                                      <li key={id} className="nav-item">
                                        {utmParams.utm_source ||
                                        utmParams.utm_medium ||
                                        utmParams.utm_campaign ||
                                        utmParams.utm_term ||
                                        utmParams.utm_content ? (
                                          <Link
                                            href={{
                                              pathname: region?.countriesURLs[i]
                                                ? `/destination/${region.countriesURLs[i]}`
                                                : "",
                                              query: {
                                                utm_source:
                                                  utmParams.utm_source,
                                                utm_medium:
                                                  utmParams.utm_medium,
                                                utm_campaign:
                                                  utmParams.utm_campaign,
                                                utm_term: utmParams.utm_term,
                                                utm_content:
                                                  utmParams.utm_content,
                                              },
                                            }}
                                          >
                                            <a className="nav-link">
                                              {countryName}
                                            </a>
                                          </Link>
                                        ) : (
                                          <Link
                                            href={
                                              region?.countriesURLs[i]
                                                ? `/destination/${region.countriesURLs[i]}`
                                                : ""
                                            }
                                          >
                                            <a className="nav-link">
                                              {countryName}
                                            </a>
                                          </Link>
                                        )}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                      {pageData?.map((page, index) => {
                        return (
                          <Fragment key={index}>
                            {page.isHeaderPage && (
                              <li
                                key={page.id}
                                className={`menu-item order-${page?.headerOrder}`}
                              >
                                {pagecontainerData.map((container, index) => {
                                  if (
                                    page.id === container.pageModuleId &&
                                    container?.pageSubModuleId?.length < 1
                                  ) {
                                    if (container.isExtrnalLink) {
                                      return (
                                        <Fragment key={index}>
                                          {utmParams.utm_source ||
                                          utmParams.utm_medium ||
                                          utmParams.utm_campaign ||
                                          utmParams.utm_term ||
                                          utmParams.utm_content ? (
                                            <a
                                              className="nav-link"
                                              target={
                                                container?.target
                                                  ? container.target
                                                  : "_blank"
                                              }
                                              rel="noreferrer"
                                              href={`${container.externalLinkUrl}?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}&utm_term=${utmParams.utm_term}&utm_content=${utmParams.utm_content}`}
                                            >
                                              {page.name}
                                            </a>
                                          ) : (
                                            <a
                                              className="nav-link"
                                              target={
                                                container?.target
                                                  ? container.target
                                                  : "_blank"
                                              }
                                              rel="noreferrer"
                                              href={`${container.externalLinkUrl}`}
                                            >
                                              {page.name}
                                            </a>
                                          )}
                                        </Fragment>
                                      );
                                    } else if (container.isPdfUrl) {
                                      return (
                                        <Fragment key={index}>
                                          <Link
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`${container.pdfUrl}`}
                                          >
                                            <a className="nav-link">
                                              {page.name}
                                            </a>
                                          </Link>
                                        </Fragment>
                                      );
                                    } else {
                                      return (
                                        <Fragment key={index}>
                                          {utmParams.utm_source ||
                                          utmParams.utm_medium ||
                                          utmParams.utm_campaign ||
                                          utmParams.utm_term ||
                                          utmParams.utm_content ? (
                                            <Link
                                              href={{
                                                pathname: `/${
                                                  container.pageSEO?.pageURL
                                                    ?.length > 0
                                                    ? container.pageSEO.pageURL
                                                    : sanitizeProjectURL(
                                                        page.name
                                                      )
                                                }`,
                                                query: {
                                                  utm_source:
                                                    utmParams.utm_source,
                                                  utm_medium:
                                                    utmParams.utm_medium,
                                                  utm_campaign:
                                                    utmParams.utm_campaign,
                                                  utm_term: utmParams.utm_term,
                                                  utm_content:
                                                    utmParams.utm_content,
                                                },
                                              }}
                                            >
                                              <a className="nav-link">
                                                {page.name}
                                              </a>
                                            </Link>
                                          ) : (
                                            <Link
                                              href={{
                                                pathname: `/${
                                                  container.pageSEO?.pageURL
                                                    ?.length > 0
                                                    ? container.pageSEO.pageURL
                                                    : sanitizeProjectURL(
                                                        page.name
                                                      )
                                                }`,
                                              }}
                                            >
                                              <a className="nav-link">
                                                {page.name}
                                              </a>
                                            </Link>
                                          )}
                                        </Fragment>
                                      );
                                    }
                                  }
                                })}
                              </li>
                            )}
                          </Fragment>
                        );
                      })}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        {toggleBtn == true && (
          <div className="main-navigation resp-menu d-block d-lg-none">
            <nav id="navigation" className="navigation">
              <ul className="row">
                <li className="menu-item-has- order-1">
                  {utmParams.utm_source ||
                  utmParams.utm_medium ||
                  utmParams.utm_campaign ||
                  utmParams.utm_term ||
                  utmParams.utm_content ? (
                    <Link
                      href={{
                        pathname: "/",
                        query: {
                          utm_source: utmParams.utm_source,
                          utm_medium: utmParams.utm_medium,
                          utm_campaign: utmParams.utm_campaign,
                          utm_term: utmParams.utm_term,
                          utm_content: utmParams.utm_content,
                        },
                      }}
                    >
                      Home
                    </Link>
                  ) : (
                    <Link href="/">Home</Link>
                  )}
                </li>
                <li className="menu-item-has-children order-2">
                  <a>Destinations</a>
                  <ul>
                    {destinationData?.map((dest, index) => {
                      return (
                        <Fragment key={index}>
                          {reginData?.map((region, index) => {
                            return (
                              <Fragment key={index}>
                                {region.countriesId.map((id, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {dest.destinationId === id &&
                                        dest.isNavShown === true && (
                                          <li
                                            key={dest.id}
                                            className="nav-item"
                                          >
                                            {utmParams.utm_source ||
                                            utmParams.utm_medium ||
                                            utmParams.utm_campaign ||
                                            utmParams.utm_term ||
                                            utmParams.utm_content ? (
                                              <Link
                                                href={{
                                                  pathname: `/destination/${
                                                    region.countriesURLs[i]
                                                      ? region.countriesURLs[i]
                                                      : "/"
                                                  }`,
                                                  query: {
                                                    utm_source:
                                                      utmParams.utm_source,
                                                    utm_medium:
                                                      utmParams.utm_medium,
                                                    utm_campaign:
                                                      utmParams.utm_campaign,
                                                    utm_term:
                                                      utmParams.utm_term,
                                                    utm_content:
                                                      utmParams.utm_content,
                                                  },
                                                }}
                                              >
                                                <a className="nav-link">
                                                  {region.countriesNames[i]}
                                                </a>
                                              </Link>
                                            ) : (
                                              <Link
                                                href={`/destination/${
                                                  region.countriesURLs[i]
                                                    ? region.countriesURLs[i]
                                                    : "/"
                                                }`}
                                              >
                                                <a className="nav-link">
                                                  {region.countriesNames[i]}
                                                </a>
                                              </Link>
                                            )}
                                          </li>
                                        )}
                                    </Fragment>
                                  );
                                })}
                              </Fragment>
                            );
                          })}
                        </Fragment>
                      );
                    })}
                    {reginData?.map((region) => {
                      return (
                        <li
                          key={region.id}
                          className="nav-item menu-item-has-children"
                        >
                          {utmParams.utm_source ||
                          utmParams.utm_medium ||
                          utmParams.utm_campaign ||
                          utmParams.utm_term ||
                          utmParams.utm_content ? (
                            <Link
                              href={{
                                pathname: `/region/${
                                  region.regionURL ? region.regionURL : "/"
                                }`,
                                query: {
                                  utm_source: utmParams.utm_source,
                                  utm_medium: utmParams.utm_medium,
                                  utm_campaign: utmParams.utm_campaign,
                                  utm_term: utmParams.utm_term,
                                  utm_content: utmParams.utm_content,
                                },
                              }}
                            >
                              <a className="nav-link">{region.name}</a>
                            </Link>
                          ) : (
                            <Link
                              href={`/region/${
                                region.regionURL ? region.regionURL : "/"
                              }`}
                            >
                              <a className="nav-link">{region.name}</a>
                            </Link>
                          )}
                          <ul className="dropdown-menu">
                            {region.countriesId.map((id, i) => {
                              return (
                                <li key={id} className="nav-item">
                                  {utmParams.utm_source ||
                                  utmParams.utm_medium ||
                                  utmParams.utm_campaign ||
                                  utmParams.utm_term ||
                                  utmParams.utm_content ? (
                                    <Link
                                      href={{
                                        pathname: `/destination/${
                                          region.countriesURLs[i]
                                            ? region.countriesURLs[i]
                                            : "/"
                                        }`,
                                        query: {
                                          utm_source: utmParams.utm_source,
                                          utm_medium: utmParams.utm_medium,
                                          utm_campaign: utmParams.utm_campaign,
                                          utm_term: utmParams.utm_term,
                                          utm_content: utmParams.utm_content,
                                        },
                                      }}
                                    >
                                      <a className="nav-link">
                                        {region.countriesNames[i]}
                                      </a>
                                    </Link>
                                  ) : (
                                    <Link
                                      href={`/destination/${
                                        region.countriesURLs[i]
                                          ? region.countriesURLs[i]
                                          : "/"
                                      }`}
                                    >
                                      <a className="nav-link">
                                        {region.countriesNames[i]}
                                      </a>
                                    </Link>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                {pageData.map((page, index) => {
                  return (
                    <Fragment key={index}>
                      {page.isHeaderPage == true && (
                        <li
                          className={`menu-item-has- order-${page.headerOrder}`}
                        >
                          {page.isActive == true ? (
                            pagecontainerData.map((container, index) => {
                              if (
                                page.id === container.pageModuleId &&
                                container.pageSubModuleId?.length < 1
                              ) {
                                if (container.isExtrnalLink === true) {
                                  return (
                                    <Fragment key={index}>
                                      {utmParams.utm_source ||
                                      utmParams.utm_medium ||
                                      utmParams.utm_campaign ||
                                      utmParams.utm_term ||
                                      utmParams.utm_content ? (
                                        <Link
                                          rel="noreferrer"
                                          href={{
                                            pathname: `${container.externalLinkUrl}`,
                                            query: {
                                              utm_source: utmParams.utm_source,
                                              utm_medium: utmParams.utm_medium,
                                              utm_campaign:
                                                utmParams.utm_campaign,
                                              utm_term: utmParams.utm_term,
                                              utm_content:
                                                utmParams.utm_content,
                                            },
                                          }}
                                        >
                                          <a className="nav-link">
                                            {page.name}
                                          </a>
                                        </Link>
                                      ) : (
                                        <Link
                                          rel="noreferrer"
                                          href={`${container.externalLinkUrl}`}
                                        >
                                          <a className="nav-link">
                                            {page.name}
                                          </a>
                                        </Link>
                                      )}
                                    </Fragment>
                                  );
                                } else if (container.isPdfUrl === true) {
                                  return (
                                    <Fragment key={index}>
                                      <Link
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${container.pdfUrl}`}
                                      >
                                        <a className="nav-link">{page.name}</a>
                                      </Link>
                                    </Fragment>
                                  );
                                } else {
                                  return (
                                    <Fragment key={index}>
                                      {utmParams.utm_source ||
                                      utmParams.utm_medium ||
                                      utmParams.utm_campaign ||
                                      utmParams.utm_term ||
                                      utmParams.utm_content ? (
                                        <Link
                                          href={{
                                            pathname: `/${
                                              container.pageSEO?.pageURL?.length
                                                ? container.pageSEO.pageURL
                                                : "/"
                                            }`,
                                            query: {
                                              utm_source: utmParams.utm_source,
                                              utm_medium: utmParams.utm_medium,
                                              utm_campaign:
                                                utmParams.utm_campaign,
                                              utm_term: utmParams.utm_term,
                                              utm_content:
                                                utmParams.utm_content,
                                            },
                                          }}
                                        >
                                          <a className="nav-link">
                                            {page.name}
                                          </a>
                                        </Link>
                                      ) : (
                                        <Link
                                          href={`/${
                                            container.pageSEO?.pageURL?.length
                                              ? container.pageSEO.pageURL
                                              : "/"
                                          }`}
                                        >
                                          <a className="nav-link">
                                            {page.name}
                                          </a>
                                        </Link>
                                      )}
                                    </Fragment>
                                  );
                                }
                              }
                            })
                          ) : (
                            <a
                              style={{ cursor: "pointer" }}
                              className="nav-link"
                            >
                              {page.name}
                            </a>
                          )}
                        </li>
                      )}
                    </Fragment>
                  );
                })}
              </ul>
            </nav>
          </div>
        )}
      </header>
      <div className="fixed-side-links">
        <div className="item">
          <a href={`mailto:${contactus.email1}`}></a>
          <span className="icon">
            <i className="far fa-envelope"></i>
          </span>
          <span className="text">{contactus.email1}</span>
        </div>
        <div className="item">
          <a href={`tel:${contactus.mainPhone}`}></a>
          <span className="icon">
            <i className="fa fa-phone"></i>
          </span>
          <span className="text">{contactus.mainPhone}</span>
        </div>
      </div>
    </Fragment>
  );
}
